export default function instructorsFunc() {
    if(document.querySelector(".instructors-list li[data-most]")){
        const allLinkAba = document.querySelectorAll(".instructors-list li[data-most]");

        if(allLinkAba.length <= 1) {
            document.querySelector(".instructors-list").style.display = "none";
        }

        const allAba = document.querySelectorAll(".side-by-side[data-most-aba]");
        allLinkAba[0].classList.add("is-active");
        allAba[0].classList.add("is-active");
        
        allLinkAba.forEach(buttonAba => {
            buttonAba.addEventListener("click", (e) => {
                e.preventDefault();
                closeAllAbas();
                let aba = buttonAba.dataset.most;
                allAba.forEach(abaAtual => {
                    if(abaAtual.dataset.mostAba == aba) {
                        abaAtual.classList.add("is-active");
                    }
                });
                buttonAba.classList.add("is-active");
            })
        });

        function closeAllAbas() {
            allAba.forEach(element => {
                element.classList.remove("is-active");
            });

            allLinkAba.forEach(element => {
                element.classList.remove("is-active");
            });
        }
    }
}