import { Swiper, Navigation, Pagination } from "swiper/dist/js/swiper.esm.js";

Swiper.use([Navigation, Pagination]);

export default () => {
    // Instâncias do Swiper aqui...

    var swiper = new Swiper('.home-courses-slider', {
        pagination: {
          el: '.swiper-pagination',
          type: 'progressbar',
        },
        navigation: {
          nextEl: '.desktop-next-slide',
          prevEl: '.desktop-prev-slide',
        },
        slidesPerView: 3,
        spaceBetween: 10,
        breakpoints: {
            1100: {
                slidesPerView: 1,
                spaceBetween: 20
            }
        },
    });

    var swiper = new Swiper('.statement-person-slider', {
      navigation: {
        nextEl: '.statement-next',
        prevEl: '.statement-prev',
      },
    });

    var swiper = new Swiper('.statement-text-slider', {
      navigation: {
        nextEl: '.statement-next',
        prevEl: '.statement-prev',
      },
    });

    var swiper = new Swiper('.statement-media-slider', {
      navigation: {
        nextEl: '.statement-next',
        prevEl: '.statement-prev',
      },
    });

    var swiper = new Swiper('.home-partners', {
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        slidesPerView: 6,
        spaceBetween: 10,
        breakpoints: {
            1200: {
                slidesPerView: 1,
                spaceBetween: 20
            }
        },
    });

    var swiper = new Swiper('.trainning-slider-container', {
      autoHeight:true,
      navigation: {
        nextEl: '.trainning-next',
        prevEl: '.trainning-prev',
      },
    });
};
