import modules from "./_modules";

modules();


document.getElementById("desktopToggleSearch").addEventListener("click", toggleSearch);
function toggleSearch(){
    var element = document.getElementById("searchInput")
    element.classList.toggle("active");
}


document.getElementById("mobileToggleSearch").addEventListener("click", toggleMobileSearch);
function toggleMobileSearch(){
    var element = document.getElementById("mobileSearch")
    element.classList.toggle("active");
}



$(function() {
    var mainHeader = $(".main-header");
    $(window).scroll(function() {    
        var scroll = $(window).scrollTop();
        //primeira section
        mainHeader.toggleClass("active", scroll > 10);
    })
})

